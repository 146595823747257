<template>
  <v-modal :title="title">
    <div class="modal-body upload-image-body d-flex flex-wrap m-auto">
      <label for="suspend-store-modal">
        Ajoutez une note/raison qui fera partie du courriel envoyé à l'administrateur de la boutique {{ storeName }}
        et aussi inclu dans le ticket de support.
      </label>
      <textarea class="form-control"
                id="suspend-store-modal"
                :value="reason"
                @input="handleChange" />
     </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" @click="$emit('cancel')">Annuler</button>
      <button type="button" class="btn btn-danger" @click="handleConfirm">Confirmer</button>
    </div>
  </v-modal>
</template>

<script>
export default {
  data () {
    return {
      reason: null
    }
  },
  props: {
    storeName: {
      type: String,
      required: true
    },
    storeId: {
      type: String,
      required: true
    },
    isSuspended: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    title () {
      return this.isSuspended
        ? `Suspendre ${this.storeName}`
        : `Activer ${this.storeName}`
    }
  },
  methods: {
    handleChange (evt) {
      this.reason = evt.target.value
    },
    handleConfirm () {
      this.$emit('confirm', this.reason)
    }
  }
}
</script>

<style scoped lang="scss">
.upload-image-body {
  max-height: 450px;
  overflow-y: auto;
}
</style>
